<template>
    <!-- <div id="nav">
        <router-link to="/">Home</router-link>
    </div> -->
    <router-view />
</template>

<style>
html {
    font-family: 'Karla';
}
</style>
