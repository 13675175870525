<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 333.92 90.75"
        :width="width"
    >
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path
                    :class="color"
                    d="M152.54,84.93l-1.39-1.79-.05,0A6.17,6.17,0,0,0,152,80.2c.18-3.69-3.66-5.94-7.36-6.2h-.12a1.36,1.36,0,0,0-1,.4l-.11.09a1.39,1.39,0,0,0-.37,1.05l.33,6.63c.1,2,.19,3.94.29,5.91a1.58,1.58,0,0,0,2.68,1,1.36,1.36,0,0,0,.37-1l-.09-1.86a7.23,7.23,0,0,0,2.36-.9l2.59,3.35a1.59,1.59,0,0,0,1.28.62,1.7,1.7,0,0,0,1.52-.87,1.25,1.25,0,0,0-.18-1.35Zm-4.1-3.22a4,4,0,0,1-2,1.39l-.29-5.78a3.78,3.78,0,0,1,2.63,2.31A2.31,2.31,0,0,1,148.44,81.71Z"
                />
                <path
                    :class="color"
                    d="M252.13,84.93l-1.39-1.79-.05,0a6.17,6.17,0,0,0,.87-2.89c.18-3.69-3.66-5.94-7.36-6.2h-.12a1.34,1.34,0,0,0-1,.4l-.11.09a1.39,1.39,0,0,0-.37,1.05c.1,2.21.22,4.42.33,6.63q.14,3,.29,5.91a1.58,1.58,0,0,0,2.68,1,1.4,1.4,0,0,0,.37-1l-.09-1.86a7.09,7.09,0,0,0,2.35-.9l2.6,3.35a1.59,1.59,0,0,0,1.28.62,1.7,1.7,0,0,0,1.52-.87,1.25,1.25,0,0,0-.18-1.35ZM248,81.71a4,4,0,0,1-2,1.39l-.29-5.78a3.78,3.78,0,0,1,2.63,2.31A2.31,2.31,0,0,1,248,81.71Z"
                />
                <path
                    :class="color"
                    d="M306.64,86.66h-5.07l-.06-2.72H304a1.43,1.43,0,1,0,0-2.86h-2.59c0-1.41,0-2.83,0-4.23h4.18a1.43,1.43,0,1,0,0-2.86h-5.67a1.49,1.49,0,0,0-1.3.66,1.4,1.4,0,0,0-.29.87c0,3.21.08,6.47.15,9.68l0,2.79a1.38,1.38,0,0,0,.33.87,1.5,1.5,0,0,0,1.29.66h6.49a1.44,1.44,0,1,0,0-2.87Z"
                />
                <path
                    :class="color"
                    d="M222.59,73.68l-.45,0c-4.19,0-5.71,4.68-5.71,7.83,0,5,2.23,8,5.83,8,4,0,5.63-3.66,5.83-6.8C228.32,79.13,226.53,74,222.59,73.68Zm2.67,8.87c0,.7-.39,4.15-3,4.15s-3-3.65-3-5.21c0,0,.13-5,2.88-5h.24C224.33,76.65,225.41,80.24,225.26,82.55Z"
                />
                <rect
                    :class="color"
                    x="64.71"
                    y="53.32"
                    width="8.56"
                    height="7.77"
                />
                <rect
                    :class="color"
                    x="325.14"
                    y="0.41"
                    width="8.78"
                    height="34.54"
                />
                <rect
                    :class="color"
                    x="325.09"
                    y="40.34"
                    width="8.56"
                    height="7.77"
                />
                <path
                    :class="color"
                    d="M121.9,5.39l-8.42,2.09v6.33h-4.89v6.84h4.53V36.84c0,5.11.43,8.2,1.87,11.09h9.21c-1.8-3.17-2.3-6.63-2.3-11.09V20.65h5.47V13.81H121.9Z"
                />
                <path
                    :class="color"
                    d="M104.55,36.55V35.33H96.64v2.52c0,2.45-1.3,4.1-4,4.1s-3.88-1.65-3.88-4.1V31.23h15.82v-6.4c0-7.2-4.6-12.09-12-12.09S80.23,17.63,80.23,24.83V36.48c0,7.2,5,12,12.31,12S104.55,43.75,104.55,36.55ZM88.73,23.38c0-2.44,1.43-4.09,3.81-4.09s3.88,1.65,3.88,4.09v4.39H88.73Z"
                />
                <path
                    :class="color"
                    d="M57.49,13.38h-8.7V36.27c0,2-1.66,5.61-4.32,5.61-2,0-3.45-1.51-3.45-4V13.38H32.23V38.93c0,5.76,2.88,9.57,8.57,9.57,3.82,0,7.26-2.3,9-6.69l.44,6.12h7.26Z"
                />
                <rect
                    :class="color"
                    x="64.76"
                    y="13.38"
                    width="8.78"
                    height="34.54"
                />
                <path
                    :class="color"
                    d="M18.06,17.92a8.85,8.85,0,0,0-8.49-5.11C3.38,12.81,0,17.27,0,23.6V38.5C0,44,3.59,48.57,8.05,48.57a8.91,8.91,0,0,0,8.28-5V58.86H25V13.38h-6ZM16.33,37.2c0,2.73-1.58,4.54-4,4.54S8.49,40.15,8.49,37V24.18c0-3.17,1.51-4.75,3.88-4.75,2.09,0,4,1.58,4,4.53Z"
                />
                <path
                    :class="color"
                    d="M215.79,12.74c-7.35,0-12.31,4.89-12.31,12.09V36.48c0,7.2,5,12,12.31,12s12-4.75,12-12V35.33h-7.92v2.52c0,2.45-1.29,4.1-4,4.1S212,40.3,212,37.85V31.23H227.8v-6.4C227.8,17.63,223.2,12.74,215.79,12.74Zm3.88,15H212V23.38c0-2.44,1.44-4.09,3.82-4.09s3.88,1.65,3.88,4.09Z"
                />
                <path
                    :class="color"
                    d="M278.18,27.05c-4.18-1.65-5.84-2.73-5.84-4.74s1.3-3,3.67-3c2.81,0,4.11,1.36,4.11,3.6v.86h7.19v-.58c0-6.12-4.09-10.43-11.44-10.43-6.76,0-11.58,3.88-11.58,10.15,0,5,2.87,7.62,7.69,9.57s7.71,3.23,7.71,6.25c0,2.37-1.44,3.46-4.1,3.46s-4.54-1.3-4.54-4.46V36.48h-7.34v1.23c0,6.19,4.25,10.79,12.16,10.79,8.13,0,11.81-5.25,11.81-10.29C287.68,32,283.57,29.14,278.18,27.05Z"
                />
                <path
                    :class="color"
                    d="M257.31,40.65V24.32c0-6.62-3.38-11.58-11.59-11.58s-11.66,4.6-11.66,10.5V24.9h8v-2c0-2.31.86-3.46,3.31-3.46,2.3,0,3.45,1.22,3.45,3.24v4.68c-9.5,1.58-16.19,3.95-16.19,11.44v.43c0,5.89,3.82,9.28,8.92,9.28a10.16,10.16,0,0,0,9.22-5c.72,3.52,3.17,5,6.55,5h2v-6C258,42.52,257.31,42,257.31,40.65Zm-8.5-2.08c0,2.3-2.09,3.89-4.53,3.89A3.2,3.2,0,0,1,240.89,39V37.71c0-3.46,2.46-5,7.92-5.76Z"
                />
                <path
                    :class="color"
                    d="M305.3,12.74c-7.34,0-12.3,4.89-12.3,12.09V36.48c0,7.2,5,12,12.3,12s12-4.75,12-12V35.33H309.4v2.52c0,2.45-1.29,4.1-4,4.1s-3.88-1.65-3.88-4.1V31.23h15.83v-6.4C317.32,17.63,312.72,12.74,305.3,12.74Zm3.89,15h-7.7V23.38c0-2.44,1.44-4.09,3.81-4.09s3.89,1.65,3.89,4.09Z"
                />
                <path
                    :class="color"
                    d="M131.18,47.93h3.67c0,3.38-1.8,5.11-4.53,5.11v2.37c5.75,0,8.78-3.67,8.78-8.49V38.14h-7.92Z"
                />
                <path
                    :class="color"
                    d="M171.82,12.81a9,9,0,0,0-8.57,5.11l-.43-4.54h-6.54V58.86H165v-14c2.3,2.52,4.32,3.68,7.85,3.68,4.67,0,8.42-4.54,8.42-10.08V23.6C181.25,17.27,177.94,12.81,171.82,12.81ZM172.75,37c0,3.17-1.44,4.75-3.81,4.75s-4-1.81-4-4.54V24c0-2.95,1.87-4.53,4-4.53,2.37,0,3.81,1.58,3.81,4.75Z"
                />
                <rect :class="color" x="188" width="8.78" height="47.93" />
                <path
                    :class="color"
                    d="M18.56,82.46c0-2.12-.07-4.52,0-6.84a1.41,1.41,0,0,0-.43-1,1.85,1.85,0,0,0-1.3-.54,1.41,1.41,0,0,0-1.31.81q-.37.78-.75,1.59l-.05.12a38.64,38.64,0,0,1-3.83,7,56.3,56.3,0,0,1-4.18-8.82,1.65,1.65,0,0,0-1.58-1.07A1.54,1.54,0,0,0,3.49,75l-.13,0,.12.36q-.06,3.65-.1,7.27l-.09,6.4a1.36,1.36,0,0,0,.41,1,1.67,1.67,0,0,0,1.2.47,1.56,1.56,0,0,0,1.64-1.5l.09-6.53c.82,1.57,1.69,3.06,2.6,4.45a1.67,1.67,0,0,0,1.41.75,1.55,1.55,0,0,0,1.12-.45,22.5,22.5,0,0,0,3.54-4.85c0,2.21.07,4.49,0,6.77a1.37,1.37,0,0,0,.41,1,1.68,1.68,0,0,0,1.2.48,1.56,1.56,0,0,0,1.64-1.51c0-2.23,0-4.49,0-6.68Z"
                />
                <path
                    :class="color"
                    d="M43.76,75.84a1.56,1.56,0,0,0-1.64-1.5,1.7,1.7,0,0,0-1.2.47,1.45,1.45,0,0,0-.41,1c0,.45,0,1,.09,1.74.18,2.71.45,6.8-1.09,8.55a2.45,2.45,0,0,1-1.78.82h-.12a1.52,1.52,0,0,1-1.19-.51c-1.55-1.64-1.23-6.72-1.08-9.16,0-.63.07-1.12.07-1.46a1.39,1.39,0,0,0-.41-1,1.68,1.68,0,0,0-1.2-.48,1.56,1.56,0,0,0-1.63,1.51c0,.55,0,1.2-.07,1.9-.22,4.77-.56,12,5.63,12.34h.4a4.77,4.77,0,0,0,3.59-1.44c2.44-2.54,2.23-7.77,2.09-11.23C43.78,76.81,43.77,76.28,43.76,75.84Z"
                />
                <path
                    :class="color"
                    d="M177.09,86.72h-5L172.06,84h2.47a1.4,1.4,0,1,0,0-2.79H172c0-1.38,0-2.77-.05-4.15h4.11a1.4,1.4,0,1,0,0-2.8H170.5a1.46,1.46,0,0,0-1.27.65,1.35,1.35,0,0,0-.29.85c0,3.14.07,6.34.14,9.5l.06,2.73a1.43,1.43,0,0,0,.31.85,1.47,1.47,0,0,0,1.27.65h6.37a1.41,1.41,0,1,0,0-2.81Z"
                />
                <path
                    :class="color"
                    d="M63.3,80.65c-.27-.15-.55-.28-.86-.43a8.76,8.76,0,0,1-1.66-1c-.24-.19-.3-.36-.2-.91s.3-.63.79-.63a4.63,4.63,0,0,1,1.41.31,1.49,1.49,0,0,0,2-1.17,1.56,1.56,0,0,0-1.06-1.79c-2.46-.88-4.53-.39-5.77,1.35a4,4,0,0,0,.61,5.08A8.9,8.9,0,0,0,61,83a8.21,8.21,0,0,1,2.2,1.36,1.08,1.08,0,0,1,.41,1.23,2.1,2.1,0,0,1-1.88,1.06,1.5,1.5,0,0,1-1.4-.8,1.46,1.46,0,0,0-1.3-.8,1.74,1.74,0,0,0-1.48.84,1.47,1.47,0,0,0,0,1.49,4.55,4.55,0,0,0,4.18,2.35c2.52,0,5.12-1.66,5.13-4.44C66.82,83.49,65.64,81.92,63.3,80.65Z"
                />
                <path
                    :class="color"
                    d="M83.4,82.13c0-2-.08-4.07-.08-6.1a1.51,1.51,0,0,0-1.56-1.51,1.55,1.55,0,0,0-1.12.45A1.45,1.45,0,0,0,80.22,76c0,2,0,4,.08,6s.07,4.18.08,6.27a1.51,1.51,0,0,0,1.55,1.52,1.59,1.59,0,0,0,1.13-.46,1.43,1.43,0,0,0,.42-1.06c0-2,0-4-.08-6Z"
                />
                <path
                    :class="color"
                    d="M108.27,84.62a1.66,1.66,0,0,0-1.34.66,3.17,3.17,0,0,1-2.61,1.2,4.28,4.28,0,0,1-3.86-2.25,4.69,4.69,0,0,1,.29-4.46,4.21,4.21,0,0,1,4.78-1.57,1.59,1.59,0,0,0,2-1.3,1.51,1.51,0,0,0-1.14-1.69,7.25,7.25,0,0,0-8.15,2.62,8,8,0,0,0-.88,7.33,7,7,0,0,0,6.76,4.36A6.87,6.87,0,0,0,109.66,87a1.31,1.31,0,0,0,.18-1.42A1.77,1.77,0,0,0,108.27,84.62Z"
                />
                <path
                    :class="color"
                    d="M200.57,74.16c-1.8,0-3.71.07-5.54.07a1.75,1.75,0,0,0-1.55,2.83c0,1.37,0,2.75.05,4.13,0,1.73.07,5.3.08,7.06a1.32,1.32,0,0,0,1.4,1.28,1.53,1.53,0,0,0,1.24-.59c.35-.48.19-1.4.16-2.25,0-1,0-2.15,0-3.25.86,0,1.82,0,2.43,0A1.38,1.38,0,0,0,200.16,82a1.44,1.44,0,0,0-.41-1,1.34,1.34,0,0,0-1-.38c-1.65,0-1.14.07-2.45.07,0-1.2-.05-2.43-.06-3.66,1.13,0,2.95,0,4.31,0A1.37,1.37,0,0,0,202,75.55a1.44,1.44,0,0,0-.41-1A1.28,1.28,0,0,0,200.57,74.16Z"
                />
                <path
                    :class="color"
                    d="M324.11,74a1.44,1.44,0,0,0-1.42,1.44V88.05A1.44,1.44,0,0,0,324,89.48a9.27,9.27,0,0,0,1,0h0a11.1,11.1,0,0,0,5.06-1.12,6.6,6.6,0,0,0,3.58-6.26C333.65,75.67,327.43,74,324.11,74Zm4.62,11.9a8,8,0,0,1-3.16.77V77c2,.31,5.2,1.39,5.2,5.19A3.77,3.77,0,0,1,328.73,85.86Z"
                />
                <path
                    :class="color"
                    d="M283.79,82.46c0-2.12-.07-4.52,0-6.84a1.45,1.45,0,0,0-.43-1,1.85,1.85,0,0,0-1.3-.54,1.43,1.43,0,0,0-1.32.81q-.37.78-.75,1.59l-.05.12a38,38,0,0,1-3.82,7,56.3,56.3,0,0,1-4.18-8.82,1.65,1.65,0,0,0-1.58-1.07A1.53,1.53,0,0,0,268.73,75l-.14,0,.13.36q-.06,3.65-.11,7.27l-.09,6.4a1.43,1.43,0,0,0,.41,1,1.71,1.71,0,0,0,1.21.47,1.55,1.55,0,0,0,1.63-1.5q.06-3.27.1-6.53c.82,1.57,1.69,3.06,2.59,4.45a1.68,1.68,0,0,0,1.41.75,1.54,1.54,0,0,0,1.12-.45,22.23,22.23,0,0,0,3.55-4.85c0,2.21.07,4.49,0,6.77a1.45,1.45,0,0,0,.41,1,1.72,1.72,0,0,0,1.21.48,1.56,1.56,0,0,0,1.64-1.51c0-2.23,0-4.49,0-6.68Z"
                />
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'Logo',
    props: {
        color: {
            type: String,
            default: 'white',
            required: false,
        },
        width: {
            type: Number,
            default: 250,
            required: false,
        },
    },
}
</script>

<style lang="css" scoped>
.white {
    fill: #fff;
    stroke: none;
}

.black {
    fill: #000;
    stroke: none;
}
</style>
