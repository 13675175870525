<template>
    <div class="sticky top-0 z-50 px-4 sm:px-6 bg-transparent">
        <div
            class="
                flex
                justify-between
                items-center
                py-6
                md:justify-start md:space-x-10
            "
        >
            <div class="flex justify-start lg:w-0 lg:flex-1"></div>

            <div class="flex space-x-10">
                <a href="#">
                    <Logo :width="160" />
                </a>
            </div>
            <div
                class="
                    flex
                    items-center
                    justify-end
                    flex-1
                    w-0
                    space-x-4
                    text-white
                "
            >
                <a href="https://facebook.com/quietpleasemusic">
                    <font-awesome-icon :icon="['fab', 'facebook']" />
                </a>
                <a href="https://instagram.com/quietpleasemusic/">
                    <font-awesome-icon :icon="['fab', 'instagram']" />
                </a>
                <a href="https://vimeo.com/quietpleaselabel">
                    <font-awesome-icon :icon="['fab', 'vimeo-v']" />
                </a>
                <a href="https://youtube.com/quietpleasemusic">
                    <font-awesome-icon :icon="['fab', 'youtube']" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import Logo from './Logo.vue'
export default {
    name: 'Header',
    components: { Logo },
}
</script>

<style></style>
