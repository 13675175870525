<template>
    <div>
        <video
            ref="video"
            playsinline
            autoplay
            :muted="mute"
            loop
            class="object-cover w-full h-full fixed top-0 left-0"
        >
            <source :src="require(`../assets/img/${video}`)" type="video/mp4" />
        </video>
        <div class="absolute bottom-20 right-0 z-10 px-4 sm:px-6">
            <button
                class="
                    w-10
                    h-10
                    text-white
                    bg-black bg-opacity-75
                    hover:bg-gray-900
                    rounded-full
                    active:shadow-lg
                    mouse
                    shadow
                    transition
                    ease-in
                    duration-200
                    focus:outline-none
                "
                v-on:click="toggleMute"
            >
                <font-awesome-icon
                    v-if="mute"
                    :icon="['fas', 'volume-mute']"
                    class="mt-1"
                />
                <font-awesome-icon v-if="!mute" :icon="['fas', 'volume-up']" />
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FullscreenVideo',
    data() {
        return {
            mute: true,
        }
    },
    props: {
        video: String,
    },
    methods: {
        toggleMute: function () {
            var vid = this.$refs.video
            this.mute = !this.mute
            vid.muted = this.mute
        },
    },
}
</script>

<style></style>
