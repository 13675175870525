<template>
    <Header />
    <FullscreenVideo video="qp_showreel.mp4" />
    <Footer />
</template>

<script>
import Header from '../components/Header.vue'
import FullscreenVideo from '../components/FullscreenVideo.vue'
import Footer from '../components/Footer.vue'
export default {
    name: 'Home',
    components: {
        Header,
        FullscreenVideo,
        Footer,
    },
}
</script>
