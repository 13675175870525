<template>
    <div class="absolute bottom-0 w-full px-4 sm:px-6 z-50 text-white">
        <div class="flex justify-between py-6">
            <div>quietplease - via frua, 12 - 20146 milano</div>
            <!-- <div>privacy</div> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',
}
</script>

<style></style>
